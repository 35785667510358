<template>
  <el-card class="box-card full-screen recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('range')">
          <el-select v-model="queryForm.isOrgCus" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('org')" :value="0"></el-option>
            <el-option :label="$t('customer')" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('cardNumber')">
          <el-input v-model="queryForm.cardNo" size="small" />
        </el-form-item>
        <el-form-item :label="$t('cac')">
          <el-input v-model="queryForm.cashNo" size="small" />
        </el-form-item>
        <el-form-item :label="$t('customerId')">
          <el-input v-model="queryForm.customerId" size="small" />
        </el-form-item>
        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('normal')" value="0"></el-option>
            <el-option :label="$t('forbidden')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="orgName(orgUserName+orgUserSurname)" :label="$t('orgUserName')" width="200">
          <template slot-scope="scope">
            {{scope.row.orgName}} ({{(scope.row.orgUserName||scope.row.customerName||'') +' '+(scope.row.orgUserSurname||scope.row.customerSurname||'')}})
          </template>
        </el-table-column>
        <el-table-column prop="accountAmount" :label="$t('accountAmount')" width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.accountAmount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('status')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('normal')}}</span>
            <span v-if="scope.row.status==1" class="text-danger">{{$t('forbidden')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" :label="$t('cardNumber')" width="160"></el-table-column>
        <el-table-column prop="cardQrCode" :label="$t('cardQrCode')" width="200"></el-table-column>
        <el-table-column prop="cashAmount" :label="$t('cashAmount')" width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.cashAmount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="cashNo" :label="$t('cac')" width="160"></el-table-column>
        <el-table-column prop="cashQrCode" :label="$t('cashQrCode')" width="160"></el-table-column>
        <el-table-column prop="customerId" :label="$t('customerId')" width="200"></el-table-column>
        <el-table-column prop="frequencyLimit" :label="$t('frequencyLimit')" width="160"></el-table-column>
        <el-table-column prop="maxTradyLimit" :label="$t('maxTradyLimit')" width="200">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.maxTradyLimit.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="orgInfoId" :label="$t('orgId')" width="160"></el-table-column>
        <el-table-column prop="orgUserId" :label="$t('orgUserId')" width="160"></el-table-column>
        <el-table-column prop="sigleTradeLimit" :label="$t('sigleTradeLimit')" width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.sigleTradeLimit.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />
  </el-card>
</template>

<script>
import card from '@/api/card'

export default {
  name: "cardList",
  data () {
    return {
      isLoading: false,
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      /**查询条件 */
      queryForm: {},
    }
  },
  methods: {
    getList () {
      let _this = this;
      _this.isLoading = true;

      card.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
  },
}
</script>

<style>
</style>