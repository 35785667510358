import ajax from '../assets/uilt/ajax';
export default {
  /**查询机构的卡账户信息 */
  getCardAccount: (req) => { return ajax.get({ url: "/back/card/get-card-account", param: req.param, success: req.success }) },
  /**分页查询卡账户 */
  getPage: (req) => { return ajax.get({ url: "/back/card/get-page", param: req.param, success: req.success }) },
  /**查询机构的卡账户流水 */
  listCardFlow: (req) => { return ajax.get({ url: "/back/card/list-card-flow", param: req.param, success: req.success }) },

  /**现金账户加款 */
  addCashAccount: (req) => { return ajax.post({ url: "/back/card/add-cashAccount", param: req.param, success: req.success }) },
  /**现金上交（员工） */
  cashToHandIn: (req) => { return ajax.post({ url: "/back/card/cash-to-hand-in", param: req.param, success: req.success }) },
  /**审核现金上交（机构） */
  checkCashToHandIn: (req) => { return ajax.post({ url: "/back/card/check-cash-to-hand-in", param: req.param, success: req.success }) },

  /**分页查询现金申请、领取记录 */
  pageCashReceive: (req) => { return ajax.get({ url: "/back/card/page-cashReceive", param: req.param, success: req.success }) },
  /**现金领取（员工） */
  getTheCash: (req) => { return ajax.post({ url: "/back/card/get-the-cash", param: req.param, success: req.success }) },
  /**现金下发（机构） */
  grantCash: (req) => { return ajax.post({ url: "/back/card/grant-cash", param: req.param, success: req.success }) },


  /**3.分页查询拆借记录 */
  pageCashLending: (req) => { return ajax.get({ url: "/back/card/page-cashLending", param: req.param, success: req.success }) },
  /**3.新增现金拆借 */
  addCashLending: (req) => { return ajax.post({ url: "/back/card/add-cashLending", param: req.param, success: req.success }) },
  /**3.新增现金拆借 */
  updateCashLendingStatus: (req) => { return ajax.post({ url: "/back/card/update-cashLending-status", param: req.param, success: req.success }) },


  /**2.分页查询机构现金充值记录（平台、机构查询） */
  pageOrgCashReceive: (req) => { return ajax.get({ url: "/back/card/page-org-cash-receive", param: req.param, success: req.success }) },
  /**2.机构现金充值申请（机构申请） */
  orgCashRechargeApply: (req) => { return ajax.post({ url: "/back/card/org-cash-recharge-apply", param: req.param, success: req.success }) },
  /**2.机构现金充值审核（平台审核） */
  orgCashRechargeCheck: (req) => { return ajax.post({ url: "/back/card/org-cash-recharge-check", param: req.param, success: req.success }) },

}